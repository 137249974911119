<template>
  <div id="manage-computerComponents">
    <v-container class="lighten-5">
      <v-container style="margin-bottom: 30px">
        <dashboard-core-app-bar />
      </v-container>
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      <form @submit.prevent="saveProduct">
        <v-row>
          <v-col md="12">
            <v-btn type="submit" class="ma-2" color="primary" dark>
              Register
              <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>General</v-card-title>
              <v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-col cols="12" v-if="this.purpose == 'new'">
                  <v-autocomplete
                    :items="productMarks"
                    item-text="Mark"
                    :return-object="true"
                    label="Choisir la marque"
                    hide-details
                    required
                    hide-selected
                    @input="onProductMarksChange"
                  ></v-autocomplete>
                </v-col>

                <v-menu
                  v-model="menuCalendar"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="releaseDate"
                      label="Date de sortie"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menuCalendar = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  label="Nom"
                  v-model="name"
                  required
                ></v-text-field>
                <v-textarea
                  name="description"
                  label="Description"
                  v-model="description"
                  hint="Hint text"
                ></v-textarea>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- ************************************** SHOW CONFIGURATIONS ************************************* -->
          <v-col sm="12">
            <v-col v-for="config in configs" v-bind:key="config.id">
              <ShowConfiguration
                :showBloc="showBloc"
                :objectMark="mark"
                :objectType="type"
                :id="config.id"
                :value="config"
                @input="
                  (newConfiguration) => {
                    config = newConfiguration;
                  }
                "
              />
            </v-col>
          </v-col>
          <!-- ************************************** CONFIGURATIONS ************************************* -->
          <v-col sm="12">
            <v-card class="elevation-5 flex d-flex flex-column">
              <v-card-title>Configurations</v-card-title>
              <v-spacer></v-spacer>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col sm="1">
                    <v-btn
                      @click="addConfiguration"
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      Add a config
                      <v-icon dark right>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-col v-for="config in configs" v-bind:key="config.id">
                  <Configuration
                    :show="show"
                    :objectMark="mark"
                    :objectType="type"
                    :id="config.id"
                    @remove="removeConfig"
                    :value="config"
                    @input="
                      (newConfiguration) => {
                        config = newConfiguration;
                      }
                    "
                  />
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- **************************************  FIN CONFIGURATIONS ************************************* -->
        </v-row>
        <!--
        <v-col md="12">
          CONFIGURATION TOTALE JSON =
          <vue-json-pretty :path="'res'" :data="configs"> </vue-json-pretty>
        </v-col>
        -->
      </form>
    </v-container>
  </div>
</template>

<script>
import db from "./../../../../../firebaseInit";
import Configuration from "./../../../components/core/Configuration.vue";
import NodesName from "./../../../../utils/nodesName";
import NodesFieldName from "./../../../../utils/nodesFieldName";
import FunctionsUtils from "./../../../../utils/functions";
import FirebaseDB from "./../../../../utils/firebaseDB";
import Constants from "./../../../../utils/constants";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import moment from "moment";
import ShowConfiguration from "./../../../components/core/ShowConfiguration";

export default {
  name: "manage-computerComponents",
  components: {
    DashboardCoreAppBar: () =>
      import("../../../../materialDashboard/components/core/AppBar.vue"),
    ShowConfiguration,
    Configuration,
    VueJsonPretty,
  },
  data() {
    return {
      name: null,
      mark: null,
      model: null,
      type: null,
      description: null,
      pricesEmpty: true,
      imagesEmpty: true,
      configs: [],
      images: [],
      purpose: null,
      allowFiltering: true,
      productMarks: [],
      show: false,
      showBloc: true,
      date: new Date().toISOString().substr(0, 10),
      menuCalendar: false,
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Catalog Settings",
          disabled: false,
          to: "/Mercarue/catalogs/catalogSettings",
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.purpose == Constants.NEW) {
      next((vm) => {
        vm.purpose = to.params.purpose;
        vm.type = to.params.type;
        // Récupérer les marques de produit
        db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
          .where(
            NodesFieldName.SUBCATEGORY,
            "array-contains",
            to.params.subcategory
          )
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              vm.productMarks.push({
                Mark: doc.data().name,
                MarkExternalId: doc.data().externalId,
                MarkId: doc.data().uniqueId,
              });
            });
          });
      });
    } else if (to.params.purpose == Constants.EDIT) {
      db.collection(NodesName.PRODUCT_CATALOG)
        .doc(to.params.mark)
        .collection(to.params.type)
        .doc(to.params.product_id)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            next((vm) => {
              vm.purpose = to.params.purpose;
              vm.type = to.params.type;
              vm.mark = to.params.mark;
              vm.product_id = doc.id;
              vm.name = doc.data().name;
              vm.model = doc.data().model;
              vm.description = doc.data().description;
              vm.configs = doc.data().configurations;
              vm.date = moment(
                new Date(doc.data().releaseDate.seconds * 1000)
              ).format("YYYY-MM-DD");
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }
  },
  methods: {
    saveProduct() {
      if (FunctionsUtils.isConfigsColorsEmpty(this.configs)) {
        alert("Configuration color  is empty, please check configurations !"); //TODO : modifier en snackbars ou toasts;
      } else {
        this.addConfigsInFirebase(this.configs);
      }
    },
    addConfiguration() {
      this.configs.unshift({
        id: FunctionsUtils.create_UUID(),
        color: "",
        images: [],
        prices: [],
      });
      this.show = true;
      this.showBloc = false;
    },
    removeConfig(idToRemove) {
      if (confirm("Are you sure ?")) {
        this.configs = this.configs.filter((config) => {
          return config.id !== idToRemove;
        });
      }
    },
    addConfigsInFirebase(configs) {
      //ajouter l'objet total avec les nouvelles configurations en base de données
      //Ou mise à jour de l'objet existant s'il existe deja
      var FirebaseCatalogRef = db
        .collection(NodesName.PRODUCT_CATALOG)
        .doc(this.mark)
        .collection(this.type)
        .doc();
      if (this.purpose == Constants.NEW) {
        FirebaseCatalogRef.set({
          name: this.name.trim(),
          model: this.mark.trim(),
          mark: this.mark.trim(),
          description: this.description.trim(),
          configurations: configs,
          deleted: false,
          creationDate: new Date(),
          releaseDate: moment(this.date).toDate(),
          uniqueId: FirebaseCatalogRef.id,
        })
          .then(() => {
            this.$router.push({
              name: "catalogSettings",
              params: { mark: this.mark, type: this.type },
            });
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      } else if (this.purpose == Constants.EDIT) {
        db.collection(NodesName.PRODUCT_CATALOG)
          .doc(this.mark)
          .collection(this.type)
          .doc(this.product_id)
          .update({
            name: this.name.trim(),
            model: this.mark.trim(),
            mark: this.mark.trim(),
            description: this.description.trim(),
            configurations: configs,
            deleted: false,
            creationDate: new Date(),
            releaseDate: moment(this.date).toDate(),
          })
          .then(() => {
            this.$router.push({
              name: "catalogSettings",
              params: { mark: this.mark, type: this.type },
            });
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      }
    },
    onProductMarksChange: function (event) {
      this.mark = event.MarkExternalId;
    },
  },
  created() {},
  computed: {
    releaseDate() {
      return FunctionsUtils.formatDate(this.date);
    },
  },
};
</script>
